.total {
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  padding-bottom: 5rem;
  background-color: var(--bckg-color);
}

.layout {
  text-align: center;
  padding: 8px;
  margin: auto;
  margin-top: calc(5rem - var(--background-height));
  max-width: 1200px;
  scroll-margin-top: 5rem;
}

.backgroundMap {
  width: 100%;
  height: var(--background-height);
  background: var(--background-url);
  background-size: var(--background-size);
  background-position: var(--background-position);
}
