.main {
  background-color: var(--sec-color);
  margin-bottom: 32px;
  padding: 16px 6px;
  font-family: var(--font-family);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  text-align: left;
}

.main h1 {
  margin: 8px 0;
  text-indent: 1.5rem;
  font-size: 1.6rem;
}

.allItems {
  display: inline-block;
  text-align: right;
  width: calc(100% - 150px);
}

.allItems a {
  text-decoration: underline;
  color: black;
}

.search {
  display: inline-block;
  width: 200px;
  color: #888;
  border-bottom: 1px #888 solid;
}

.hidden {
  display: none;
}

.list {
  list-style: none;
  margin: auto;
  padding: 0;
  text-align: center;
}

.slide {
  display: inline-block;
  vertical-align: top;
  margin: 1px;
  width: calc(50% - 7px);
  height: 54px;
  padding: 4px 6px;
  background: var(--bckg-color);
  border: 1px #aaaaaa solid;
  border-radius: var(--border-radius);
  outline: 100vmax solid rgb(0 0 0 / var(--_o, 0%));
  outline-offset: -100vmax;
  -webkit-mask: linear-gradient(#000 0 0);
  mask: linear-gradient(#000 0 0);
}

.notActiveSlide {
  transition: outline-color 0.4s;
}

.link {
  text-decoration: none;
  color: black;
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
}

.activeSlide {
  border: 1px var(--main-color) solid;
  color: var(--main-color);
  box-shadow: var(--box-shadow);
}

.itemIcon {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 100%;
}

.slide div {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  width: calc(100% - 27px);
}

.notActiveSlide:hover {
  --_o: 10%;
}

.link:hover,
.link:active {
  text-decoration: underline;
}

.mobile {
  display: inline-block;
}

.desktop {
  display: none;
}

@media (min-width: 30rem) {
  .slide {
    max-width: 218px;
  }
  .desktop {
    display: inline-block;
  }
  .mobile {
    display: none;
  }

  .allItems {
    width: calc(100% - 290px);
  }
}
