.main {
  display: inline-block;
  /* max-width: 2000px; */
  margin: 0px;
  padding: 4px;
  background-color: transparent;
}

.order a {
  text-decoration: none;
}

.unit {
  text-align: center;
  display: inline-block;
  width: 140px;
  margin: 6px 4px;
  padding: 8px;
  border: var(--border);
  border-radius: var(--border-radius);
  line-height: 2rem;
  min-width: 120px;
}

.alive {
  background-color: #fffef5;
}

.dead {
  background-color: #ff8888;
}

.token {
  width: 64px;
}

.name {
  font-family: var(--font-family);
  color: var(--black-color);
  font-size: 1.5rem;
  font-weight: bold;
}

.value {
  font-size: 2rem;
  text-decoration: none;
  color: var(--black-color);
}
