.main {
  margin: 2rem auto;
  background-color: var(--bckg-color);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 16px;
  box-shadow: var(--box-shadow);
}

.card {
  margin-bottom: 40px;
  text-align: left;
}

.name {
  display: inline-block;
  font-weight: bold;
}

.datetime {
  display: inline-block;
  padding: 0 32px;
}

.comment {
  margin: 16px 0;
}
