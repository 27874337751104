.bullets {
  margin: 2px;
  padding: 0;
}

.border {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin: 0 1px 0 0;
  padding: 0;
  border: 1px solid gray;
  border-radius: 5px;
}

.circle {
  visibility: hidden;
  width: 8px;
  height: 8px;
  margin: 2px;
  border: 4px solid gray;
  border-radius: 3px;
  box-sizing: border-box;
}

.active {
  visibility: visible;
  border-color: #da1010;
}
