.group {
  display: inline-block;
  margin: 0 4px;
  vertical-align: top;
  width: 32%;
  min-width: max-content;
}

h3 {
  font-size: 1rem;
  margin: 12px 0 2px 16px;
}

.line {
  text-align: left;
  margin: 0 4px;
}

.name {
  margin: 2px;
  font-size: 0.8rem;
}

.expertise {
  margin: 2px;
  font-size: 0.8rem;
}

.added {
  font-size: 0.7rem;
  font-style: italic;
  color: #32443e;
}
