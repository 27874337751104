.main {
  background-color: var(--sec-color);
  margin-bottom: 32px;
  padding: 16px 6px;
  font-family: var(--font-family);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  text-align: left;
}

.list {
  list-style: none;
  margin: auto;
  padding: 0;
  text-align: center;
}

.link {
  text-decoration: none;
  color: black;
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
}

.card {
  width: calc(50% - 7px);
  display: inline-block;
  vertical-align: top;
  margin: 1px;
  background-color: var(--bckg-color);
  border: 1px #aaaaaa solid;
  border-radius: var(--border-radius);
  outline: 100vmax solid rgb(0 0 0 / var(--_o, 0%));
  outline-offset: -100vmax;
  -webkit-mask: linear-gradient(#000 0 0);
  mask: linear-gradient(#000 0 0);
  transition: outline-color 0.4s;
}

.card:hover {
  --_o: 10%;
}

.image {
  display: inline-block;
  vertical-align: middle;
  height: 80px;
  width: 60px;
  object-fit: cover;
  object-position: 50% top;
  border-top-left-radius: calc(var(--border-radius) - 1px);
  border-bottom-left-radius: calc(var(--border-radius) - 1px);
  /* border-right: 1px #aaaaaa solid; */
}

.placeholder {
  display: inline-block;
  vertical-align: middle;
  height: 80px;
  width: 60px;
  background-color: bisque;
  border-top-left-radius: calc(var(--border-radius) - 1px);
  border-bottom-left-radius: calc(var(--border-radius) - 1px);
}

.title {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 60px);
  text-align: center;
  padding: 4px;
}

@media (min-width: 30rem) {
  .card {
    max-width: 218px;
  }
}
