.container {
  position: fixed;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  bottom: 70px;
  right: 5vw;
  background-color: var(--sec-color);
  opacity: 0.9;
  box-shadow: var(--box-shadow);
  text-align: center;
  font-size: 2rem;
  color: var(--main-color);
  cursor: pointer;
  z-index: 10;
}

.svg {
  margin: 13px;
  opacity: 0.9;
}
