.link {
  position: relative;
}

.button {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px transparent solid;
  border-radius: var(--border-radius);
}

.letter {
  font-family: var(--font-family);
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--main-color);
}

@media (hover: hover) {
  .link:hover .button {
    border: 1px var(--main-color) solid;
  }
}
