.form {
  max-width: 40rem;
  margin: 2rem auto;
  background-color: var(--bckg-color);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 16px;
  box-shadow: var(--box-shadow);
  text-align: left;
}
