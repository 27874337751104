.form {
  max-width: 40rem;
  margin: 2rem auto;
  background-color: var(--bckg-color);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 16px;
  box-shadow: var(--box-shadow);
  text-align: left;
}

.form h1 {
  font-size: 1.6rem;
  text-indent: 1rem;
}

.form label,
.form input,
.form textarea {
  display: block;
  width: 100%;
  padding: 8px;
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
  border: var(--border);
  border-radius: 4px;
}

.form textarea {
  resize: none;
}

.form input:focus,
.form textarea:focus {
  outline: none;
  border: 1px var(--main-color) solid;
}

.invalid {
  padding-left: 20px;
  font-style: italic;
  color: var(--main-color);
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: var(--sec-color);
  color: var(--main-color);
  border: none;
}

.actions button[type="button"] {
  background-color: transparent;
  color: var(--black-color);
}

.actions button:hover {
  background-color: var(--main-color);
  color: var(--bckg-color);
}

.actions button[type="button"]:hover {
  background-color: var(--main-color);
  color: var(--bckg-color);
}

.actions button:disabled,
.actions button[disabled] {
  background-color: #eeeeee;
  color: #888888;
}
