.card {
  display: inline-block;
  margin: 4px;
  padding: 0rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--bckg-color);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 30%;
  max-width: 159px;
}

.card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: 0% 0%;
  outline: 100vmax solid rgb(0 0 0 / var(--_o, 0%));
  outline-offset: -100vmax;
  -webkit-mask: linear-gradient(#000 0 0);
  mask: linear-gradient(#000 0 0);

  cursor: pointer;
  transition: outline-color 0.4s;
}

.image:hover {
  --_o: 30%;
}

.title {
  color: var(--black-color);
  padding: 4px 2px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.2rem;
}

.card a {
  text-decoration: none;
}

.card a:hover,
.card a:active {
  color: black;
  text-decoration: underline;
}

@media (min-width: 30rem) {
  .image {
    height: auto;
  }
}
