.total {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  padding: 2rem 1rem;
  background-color: #ffffff;
  background-image: url("../assets/footer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right 0% bottom 0%;
}

.logo {
  text-align: center;
}
.logo h1 {
  font-size: 2.4rem;
  margin: 2px;
  color: #911e42;
}

.image {
  width: 120px;
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 4px;
  color: var(--black-color);
  font-size: 0.8rem;
}

.main {
  margin: 0 auto;
  max-width: 600px;
  color: #fcf4f7;
  font-family: var(--font-family);
  border-radius: var(--border-radius);
}
