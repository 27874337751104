.main {
  max-width: 800px;
  margin: auto;
  padding: 16px 10px;
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--bckg-color);
  scroll-margin-top: 56px;
  text-align: left;
}

.main h1 {
  text-indent: 1.5rem;
}

.main ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main li {
  margin: 8px 0;
}

.main a {
  text-decoration: none;
}

.session {
  margin: 18px 0;
}

.title {
  background-color: var(--sec-color);
  padding: 10px 10px;
  line-height: 1.2rem;
}

.code,
.name,
.date {
  display: inline-block;
  color: var(--black-color);
  font-family: var(--font-family);
  font-weight: bold;
}

.code {
  vertical-align: middle;
  font-size: 1rem;
  width: 70px;
}

.name {
  vertical-align: middle;
  font-size: 1.2rem;
  width: calc(100% - 70px);
}

.date {
  display: none;
}

.description {
  color: var(--black-color);
  padding: 4px 8px;
  font-size: 0.9rem;
  text-indent: 12px;
}

@media (min-width: 30rem) {
  .date {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    width: 90px;
  }
  .name {
    width: calc(100% - 160px);
  }
}
