.main {
  margin: 16px auto;
}

.avatar {
  display: block;
  margin-bottom: 8px;
  margin-left: 5%;
  padding: 0rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--bckg-color);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 38%;
  max-width: 212px;
}

.token {
  text-align: left;
  margin-left: 5%;
  margin-bottom: 5px;
  padding: 0rem;
}

.token_svg {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 2px;
  vertical-align: middle;
}

.token_button {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
  font: inherit;
  font-family: var(--font-family);
  text-decoration: underline;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--bckg-color);
  color: var(--black-color);
  border: none;
}

.token_link {
  font-family: var(--font-family);
  text-decoration: underline;
  cursor: pointer;
  color: var(--black-color);
}

.token_button:hover,
.token_link:hover {
  text-decoration: none;
}

.image {
  max-width: 100%;
  height: auto;
}

.title {
  padding: 0 4px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: bold;
}

.classList {
  display: flex;
  justify-content: center;
}

.class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 2px;
  padding: 6px;
  border-radius: 12px;
  width: 45%;
  max-width: 200px;
  background-color: #ef5350;
  color: #fafafa;
  font-size: 1.2rem;
  min-height: 60px;
  vertical-align: middle;
}

.class_name {
  margin: 0;
}

.class_archetype {
  display: block;
  font-size: 0.8rem;
  margin: 0px;
  margin-bottom: 4px;
}

.table {
  display: inline-block;
  vertical-align: top;
  margin: 4px;
  padding: 6px 0px;
  border-radius: 16px;
  border: 2px solid;
}

.table_header {
  border-bottom: 2px solid;
  padding: 0 14px 4px 14px;
}

.table_item {
  display: block;
  padding: 2px 12px;
  font-size: 1rem;
}

.table_item_value {
  padding: 2px;
  padding-left: 6px;
  font-size: 1.2rem;
}

.skills {
  border-color: #0d47a1;
}

.passives {
  border-color: #00695c;
}

.languages {
  border-color: #26c6da;
}

.senses {
  border-color: #9c27b0;
}

.tools {
  border-color: #b71c1c;
}

.advantages {
  border-color: #ff5722;
}

.disadvantages {
  border-color: #26a69a;
}

.resistances {
  border-color: #aed581;
}
