.main {
  max-width: 800px;
  margin: auto;
  padding: 16px 20px;
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--bckg-color);
  scroll-margin-top: 56px;
}

.main img {
  max-width: 100%;
  padding: 16px 0;
}

.title {
  font-family: var(--font-family);
  font-size: 1.6rem;
  font-weight: bold;
  margin: 4px 0 0 0;
}

.category {
  font-family: "Cambria", serif;
}

.owner {
  margin: 8px 0;
  text-align: left;
  text-indent: 1rem;
}

.owner a {
  padding: 0 4px;
  text-decoration: none;
  color: var(--black-color);
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.2rem;
}

.owner a:hover,
.owner a:active {
  text-decoration: underline;
}
.description {
  text-align: left;
  text-indent: 1rem;
}

.description p {
  margin: 8px 0;
}

.link {
  display: block;
  margin: 10px auto;
  padding: 0 12px;
  max-width: 800px;
  text-align: left;
  font-family: var(--font-family);
  text-decoration: underline;
  color: black;
}
