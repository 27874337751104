@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");

:root {
  --black-color: #3f3f3f;
  --gray-color: #cccccc;
  --font-family: "El Messiri", sans-serif;
  --box-shadow:  0 2px 8px rgba(0, 0, 0, 0.25);
  --border-radius: 8px;
  --border: 1px var(--black-color) solid;
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
}

h1 {
  color: var(--main-color, --black-color);
  font-family: var(--font-family);
}
