.main {
  margin: 16px auto;
}

.avatar {
  display: block;
  margin-bottom: 10px;
  margin-left: 5%;
  padding: 0rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--bckg-color);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 38%;
  max-width: 212px;
}

.image {
  max-width: 100%;
  min-height: 200px;
  height: auto;
}

.title {
  padding: 4px;
  text-align: center;
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: bold;
}

.link {
  text-align: left;
  padding: 0 3%;
  font-family: var(--font-family);
  text-decoration: underline;
  cursor: pointer;
  color: var(--black-color);
}

.link:hover {
  text-decoration: none;
}

.section {
  text-align: left;
  padding: 0 3%;
  scroll-margin-top: 52px;
}

.section p {
  margin: 4px 0;
}

.section h3 {
  position: sticky;
  top: 52px;
  margin: 8px 0;
  padding-bottom: 4px;
  text-align: left;
  text-indent: 1rem;
  background-color: var(--bckg-color);
  color: var(--main-color);
  border-bottom: 2px var(--main-color) solid;
}

.section span {
  padding: 0 5px;
}

.line {
  margin: 1rem 3%;
  border-bottom: var(--border);
}

.abilities {
  display: block;
}

.ability {
  display: inline-block;
  background-color: var(--bckg-color);
  margin: 8px 2px;
  padding: 3px;
  border: var(--border);
  border-radius: var(--border-radius);
  min-width: 40px;
  max-width: 60px;
  width: 15%;
}

.ability_abbr {
  font-size: 1rem;
}

.ability_value {
  font-size: 1rem;
}

.ability_mark {
  display: inline-block;
  padding-right: 4px;
  color: #888888;
  font-size: 0.8rem;
}

.ability_mod {
  font-size: 1.2rem;
}

.ability_save {
  border-top: var(--border);
  font-size: 1.2rem;
}

.section .feature_title {
  font-weight: bold;
  text-indent: 0.85rem;
}

.block {
  margin-bottom: 16px;
}

.feature_uses {
  font-style: italic;
  font-size: 1rem;
}

.feature_uses span {
  vertical-align: middle;
}

.use_button {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 6px;
  vertical-align: middle;
}
