.numberField {
  --field-border-color: var(--black-color);
  --field-border-color-disabled: var(--gray-color);
  --field-background: white;
  --text-color: var(--black-color);
  --text-color-disabled: var(--gray-color);
  --focus-ring-color: var(--main-color);
  --invalid-color: red;

  margin-bottom: 8px;

  .group {
    display: flex;
    width: fit-content;
  }

  .button {
    background: var(--field-background);
    border: 1px solid var(--field-border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    font-size: 1.4rem;
    width: 2.3rem;
    padding: 0;

    &[slot="decrement"] {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }

    &[slot="increment"] {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }

    &[data-pressed] {
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1);
      background: #eeeeee;
    }
  }

  .input {
    background: var(--field-background);
    border: 1px solid var(--field-border-color);
    border-radius: 0;
    color: var(--text-color);
    margin: 0 -1px;
    z-index: 1;
    font-size: 1rem;
    padding: 0.429rem 0.571rem;
    outline: none;
    width: 3rem;
    flex: 1;
  }

  &:focus-within {
    .input,
    .button {
      border-color: var(--focus-ring-color);
    }
  }
}

@media (forced-colors: active) {
  .numberField {
    --field-border-color: ButtonBorder;
    --field-border-color-disabled: GrayText;
    --field-background: Field;
    --text-color: FieldText;
    --text-color-disabled: GrayText;
    --focus-ring-color: Highlight;
    --invalid-color: LinkText;
  }
}
