.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 4rem; */
  padding: 0 5%;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sec-color);
  box-shadow: var(--box-shadow);
  z-index: 10;
}

.header a {
  text-decoration: none;
}

.header h1 {
  margin: 8px 0 4px 0;
  height: 100%;
  vertical-align: top;
  color: var(--main-color);
  font-family: var(--font-family);
  font-size: 1.6rem;
}

.header .mobile {
  display: inline-block;
}

.header .desktop {
  display: none;
}

@media (min-width: 30rem) {
  .header .desktop {
    display: inline-block;
  }
  .header .mobile {
    display: none;
  }
}
